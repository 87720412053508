import React from "React";
import {useState} from 'react';
import Layout from "../components/Global/Layout";
import { graphql, Link } from "gatsby"
import { Helmet } from "react-helmet"
import { motion } from 'framer-motion';

import "../assets/styles/expertise.scss"

import lanImg from "../assets/images/languages.png"


export const query = graphql`
  query Expertise {
    allWpService {
      nodes {
        id
        title
        serviceMeta {
          serviceDescription
        }
      }
    }
  }
`

export default (data) => {

    const serviceData = data.data.allWpService.nodes

    const current = "expertise"

    const [expertiseNav, setexpertiseNav] = useState(1);


    return(
        <>
        <Layout current={current}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Our passion - Develescope</title>
          <meta name="description" content="Devloscope prides itself on its wide range of solutions, and technical knowledge to provide our clients with quality service that can create value for thier organization." />
        </Helmet>
        <section className="section serv-page">
            <div className="container">
                <div className="top-txt">
                    <h1>Our Expertise</h1>
                    <p>Develoscope prides itself on its wide range of solutions, and technical knowledge to provide our clients with quality service that can create value for thier organization.</p>
                </div>
                <div className="serv-nav">
                    <h4 className={expertiseNav === 1 ? "active" : ""} onClick={() => {setexpertiseNav(1)}}>What we offer</h4>
                    <h4 className={expertiseNav === 2 ? "active" : ""} onClick={() => {setexpertiseNav(2)}}>Languages</h4>
                    <h4 className={expertiseNav === 3 ? "active" : ""} onClick={() => {setexpertiseNav(3)}}>Services</h4>
                </div>
              </div>
        </section>

                <section className={expertiseNav === 1 ? "active serv-whole section" : "serv-whole section"}>
                  <div className="container">
                    <h3>What we offer you</h3>
                    <div className="columns is-multiline three-points">
                      <div className="column is-6">
                        <div className="wrap">
                          <h3 className="one">1</h3>
                          <h4>Client managed model</h4>
                          <p>Dedicated developers and QA resources (Client managed or Develoscope managed resources). In this model our developers can work under your direction. Partnering up to create provie you with a quality service.</p>
                        </div>
                      </div>
                      <div className="column is-6">
                        <div className="wrap">
                            <h3 className="two">2</h3>
                            <h4>We can lead on your behalf</h4>
                            <p>We can guide your team on your behalf with a project manager and adequate scale of team members engaged in this project. Client provides infrastructure, software architecture and product/process ownership.</p>
                          </div>
                      </div>
                      <div className="column is-6 third">
                      <div className="wrap">
                          <h3 className="three">3</h3>
                          <h4>Flexibility model</h4>
                          <p>Dedicated team(s) and development services. This model offers reliable delivery of solutions and scalable flexible services in collaboration with the client.</p>
                        </div>
                      </div>
                    </div>
                </div>
              </section>
            
        
        <section className={expertiseNav === 2 ? "active section serv-lang has-text-centered" : "section serv-lang"}>
          <h3>Our Favourite Languages</h3>
          <p>Programming languages are tools, and we choose different tools for different jobs. By choosing the best tool for the job, we can create an amazing solution!</p>
          <img src={lanImg} alt=""/>
        </section>


        <section className={expertiseNav === 3 ? "active section serv-summ" : "section serv-summ"}>
          <div className="container">
            <div className="columns is-multiline">
            {serviceData.length ? (
                    serviceData.map((service)  => 

                    <div className="column is-half" key={service.id}>
                      <div className="serv-wrap">
                        <h3>{service.title}</h3>
                        <h5><i/>Learn More</h5>
                        <p>{service.serviceMeta.serviceDescription}</p>
                      </div>
                    </div>

            )) : ''}
            </div>
          </div>
        </section>
        </Layout>
        </>
    )
}